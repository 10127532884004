<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Associate <span class="font-weight-black">{{  itemType.type }}</span></span>
        <span>with Agenda Styles</span>
      </v-card-title>
      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <template>
          <div v-for="(item, index) in items" :key="item.id"  class="py-2">
            <v-row class="pt-4 pb-2">
              <v-col>
                <v-switch
                  inset
                  :label="item.name"
                  color="secondary"
                  class="mt-0 pa-0"
                  @change="itemAssociatedChange(item)"
                  v-model="item.associated"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>

            <v-row v-if="index !== items.length - 1">
              <v-divider class="custom-divider"></v-divider>
            </v-row>
          </div>
        </template>

        <template v-if="items.length === 0">
          <div>
            <p>There are no Agenda Style's Added</p>
          </div>
        </template>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

function initialState () {
  return {
    items: []
  }
}

export default Vue.extend({
  name: 'ItemTypeAssociateAgenda',
  props: {
    itemType: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => initialState(),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    itemAssociatedChange (item) {
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/item-type/' + this.itemType.id + '/associate-agenda/' + item.id)
    }
  },
  watch: {
    show (value) {
      if (value) {
        axios.get(process.env.VUE_APP_BACKEND_URL + '/api/item-type-agenda-style-associates/' + this.itemType.id)
          .then((res) => {
            this.items = res.data
          })
      }
    }
  }
})
</script>
