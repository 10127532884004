<template>

  <v-dialog
    width="600"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

        <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
          <span>
            <span v-if="modeType=='create'">New Item Type</span>
            <span v-if="modeType=='edit'">Edit Item Type</span>
            <span v-if="modeType=='duplicate'">Duplicate Item Type</span>
            <span> for <span class="font-weight-black">{{ boardName }}</span></span>
          </span>
          <span v-if="modeType=='edit'||modeType=='duplicate'"><span class="font-weight-black">{{ itemType.type }}</span></span>
        </v-card-title>

        <span class="modal-close-btn">
          <v-btn
            icon
            color="grey"
            @click="show=false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </span>

        <v-card-text class="text-center">

          <v-row>

            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    :label="modeType==='duplicate'?'New Type':'Type'"
                    v-model="aType"
                    :rules="rules.typeRules"
                    :counter="50"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                    outlined
                    :label="modeType==='duplicate'?'New Description':'Description'"
                    v-model="aDescription"
                    :rules="rules.descRules"
                    :counter="512"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>

          </v-row>

        </v-card-text>

        <v-card-actions class="d-flex align-center justify-center">
          <v-spacer></v-spacer>
          <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
          <v-btn depressed width="130" color="secondary" @click="childFunction">
            <span v-if="modeType=='create'" @click="submit()">Add</span>
            <span v-if="modeType=='edit'" @click="submit()">Update</span>
            <span v-if="modeType=='duplicate'" @click="submit()">Duplicate</span>
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'ItemTypeCreateUpdate',
  props: {
    boardSelected: {},
    boardName: String,
    itemType: {},
    value: {
      required: true,
      type: Boolean
    },
    modeType: {
      required: true,
      type: String
    }
  },
  data: () => ({
    valid: true,
    saving: false,
    form: {
      id: '',
      type: '',
      client_id: '',
      board_id: '',
      description: ''
    },
    rules: {
      typeRules: [
        v => !!v || 'Type is required',
        v => (v && v.length <= 50) || 'Type must be less than 50 characters'
      ],
      descRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 512) || 'Description must be less than 512 characters'
      ]
    }
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    aType: {
      get () {
        return this.itemType.type
      },
      set (val) {
        this.form.type = val
      }
    },
    aDescription: {
      get () {
        return this.itemType.description
      },
      set (val) {
        this.form.description = val
      }
    }
  },
  methods: {
    childFunction () {
      this.$emit('parent-function')
      // this.show = false
    },
    submit () {
      const formPassedValidation = this.$refs.form.validate()
      if (formPassedValidation === false) {
        return false
      }
      this.saving = true

      this.form.client_id = this.$store.state.client.activeClient.clientID
      this.form.board_id = this.boardSelected

      const axiosAction = (this.modeType === 'create' || this.modeType === 'duplicate') ? 'post' : 'put'
      const axiosUrl = (this.modeType === 'edit') ? '/api/item-types/' + this.itemType.id : '/api/item-types'

      axios({
        method: axiosAction,
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data: this.form
      }).then(() => {
        this.resetModal()
        this.show = false
        this.$emit('reload', true)
      }).catch(error => {
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      })
    },
    resetModal () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.form = {
        status: 'active'
      }
      this.saving = false
    }
  },
  watch: {
    show: function (value) {
      this.form.client_id = this.$store.state.client.activeClient.clientID

      // if (this.modeType === 'create') {
      //   if (this.$refs.form) {
      //     this.$refs.form.reset()
      //   }
      //   this.aType = ''
      //   this.aDescription = ''
      // } else {
      //   console.log('itemtype', this.itemType)
      //   this.aType = this.itemType.type
      //   this.aDescription = this.itemType.description
      // }

      if (value === true && this.modeType === 'edit') {
        this.form.type = this.itemType.type
        this.form.description = this.itemType.description
      } else if (value === true && this.modeType === 'create') {
        this.resetModal()
      }
    }
  }
})
</script>
